<script>
import Layout from "../../layouts/main";

export default {
  components: { Layout },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {},
  mounted() {},

  methods: {},
};
</script>

<template>
  <Layout>
    permission
  </Layout>
</template>
